import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useInvoicesList() {

    
    //User toast
    const toast = useToast()

    const refInvoiceListTable = ref(null)

    const tableColumns = [
        {key: 'invoice_number', label:'發票編號', sortable: true},
        {key: 'invoice_date', label: '發票日期', sortable: true},
        {key: 'client', label: '客戶編號'},
        {key: 'client_company_name_cn', label:'客戶名稱', sortable: true},
        {key: 'contract.contract_code', label: '合約編號'},
        {key: 'project.project_id', label: '項目編號'},
        {key: 'user.name', label: '銷售顧問'},
        {key: 'total_amount', label:'應付金額', sortable: true},
        {key: 'total_paid', label:'已付金額', sortable: true},
        {key: 'total_remain', label:'餘額'},
        {key: 'invoice', label:'發票'},
        {key: 'is_paid', label:'已付款'},
        {key: 'is_audit', label:'已審核'},
        {key: 'document', label: '文件'},
        //{key: 'invoice_date', label:'INVOICE DATE', sortable: true},
        {key: 'created_at', label:'創建日期', sortable: true},
        //{key: 'total_amount', label:'AMOUNT(HK$)', sortable: true},
        //{key: 'export', label:'Invoice', sortable: true},
        {key: 'actions'},
    ]

    const tableReportColumns = [
        {key: 'invoice_number', label:'發票編號', sortable: true},
        {key: 'invoice_date', label: '發票日期', sortable: true},
        {key: 'client', label: '客戶編號'},
        {key: 'client_company_name_cn', label:'客戶名稱', sortable: true},
        {key: 'contract.contract_code', label: '合約編號'},
        {key: 'project.project_id', label: '項目編號'},
        {key: 'user.name', label: '銷售顧問'},
        {key: 'total_amount', label:'應付金額', sortable: true},
        {key: 'total_paid', label:'已付金額', sortable: true},
        {key: 'total_remain', label:'餘額'}
    ]

    //Filter
    const userFilter = ref(null)
    const clientFilter = ref(null)
    const dateFromFilter = ref(null)
    const dateToFilter = ref(null)
    const statusFilter = ref(null)

    const perPage = ref(10)
    const totalInvoices = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('invoice_date')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalInvoices.value,
        }
    })

    const refetchData = () => {
        refInvoiceListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, userFilter, clientFilter, dateFromFilter, dateToFilter,statusFilter], () => {
        refetchData()
    })

    const statusOptions = ref([
        {value: null, label: '全部', listName: '發票列表'},
        {value: 1, label: '待支付', listName: '待支付發票列表'},
        {value: 2, label: '已支付待審核', listName: '已支付發票列表'},
        {value: 3, label: '已審核', listName: '已審核發票列表'},
        {value: 4, label: '已刪除', listName: '已刪除發票列表'},
    ]);

    const fetchInvoices = (ctx, callback) => {
        store.dispatch('invoice/fetchInvoices',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            create_id: userFilter.value,
            client_id: clientFilter.value,
            date_from: dateFromFilter.value,
            date_to: dateToFilter.value,
            payment_status: statusFilter.value
        })
        .then(response =>{
            const {invoices, total} = response.data
            callback(invoices)
            totalInvoices.value = total
        
        })
        .catch((error) => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching invoice list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    return {
        fetchInvoices,
        tableColumns,
        perPage,
        currentPage,
        totalInvoices,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refInvoiceListTable,
        ability,
        refetchData,
        userFilter,
        clientFilter,
        dateFromFilter,
        dateToFilter,
        statusFilter,
        tableReportColumns,
        statusOptions
    }

}